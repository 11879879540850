// Generated by Framer (7af8adc)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["iXZIipyQC"];

const variantClassNames = {iXZIipyQC: "framer-v-stxged"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "iXZIipyQC", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "iXZIipyQC", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-zIHC4", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-stxged", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"iXZIipyQC"} ref={ref} style={{backgroundColor: "rgb(17, 17, 17)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-zIHC4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zIHC4 * { box-sizing: border-box; }", ".framer-zIHC4 .framer-thawjn { display: block; }", ".framer-zIHC4 .framer-stxged { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; min-height: 36px; min-width: 48px; overflow: hidden; padding: 10px 16px 10px 16px; position: relative; width: min-content; will-change: transform; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zIHC4 .framer-stxged { gap: 0px; } .framer-zIHC4 .framer-stxged > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-zIHC4 .framer-stxged > :first-child { margin-left: 0px; } .framer-zIHC4 .framer-stxged > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 */
const FramermsAQf_Xpf: React.ComponentType<Props> = withCSS(Component, css, "framer-zIHC4") as typeof Component;
export default FramermsAQf_Xpf;

FramermsAQf_Xpf.displayName = "Category Tab";

FramermsAQf_Xpf.defaultProps = {height: 36, width: 48};

addFonts(FramermsAQf_Xpf, [])